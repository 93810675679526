import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({ component: Component, path, layout: Layout, ...rest }) => {
  Layout = Layout === undefined ? (props) => <>{props.children}</> : Layout;

  if (path === "/BucketDetails") {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return (
        <Route
          to="/BucketDetails"
          component={() => {
            window.location.href =
              "https://apps.apple.com/ca/app/buckets-investing/id1599720367";
            return null;
          }}
        />
      );
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return (
        <Route
          to="/BucketDetails"
          component={() => {
            let redirect = window.location.href;
            let regex = /[?&]([^=#]+)=([^&#]*)/g,
              params = {},
              match;
            while ((match = regex.exec(redirect))) {
              params[match[1]] = match[2];
            }
            const { id } = params;
            let urlScheme = "bucketid://BucketDetails?id=" + id;
            setTimeout(function () {
              window.location =
                "https://apps.apple.com/ca/app/buckets-investing/id1599720367";
            }, 25);
            window.location = urlScheme;
            return <Redirect to="/" />;
          }}
        />
      );
    }
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
